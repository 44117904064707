<template>
  <div class="container-flex mx-auto">
    <div class="list-container">
      <!-- PlaybookList -->
      <base-list :sort-by-prop="sortBy"
                 :sort-desc-prop="sortDesc"
                 :items="playbookList"
                 :totalItems="totalItems"
                 :fields=getFields()
                 :slots="templates"
                 :loading="loading"
                 :asyncFilter="filterList"
                 :no-local-sort-prop="true"
                 @page-changed="handlePageChanged"
                 @sortingChanged="handleSortingChanged"
      >
        <template slot="title">
          <div class="title ml-1">
            {{ staticText.playbooksDisplayName }}
          </div>
        </template>

        <template slot="leftListActions">
          <div class="my-auto d-flex align-items-center">
            <b-dropdown :id="'action-dropdown-202103211105-'"
                        variant="btn-outline"
                        class="mx-2 actions-dropdown my-auto"
                        no-caret
                        left
            >
              <template v-slot:button-content>
                <div class="py-1 d-flex align-items-center">
                  <div>
                    <div v-if="showActive">
                      <i class="fas fa-file mr-1"></i>
                      {{ staticText.showPlaybooksLabel }}
                    </div>
                    <div v-else-if="showTemplates">
                      <i class="fas fa-clone mr-1"></i>
                      {{ staticText.showTemplateLabel }}
                    </div>
                    <div v-else>
                      <i class="fas fa-archive mr-1"></i>
                      {{ staticText.showInactiveLabel }}
                    </div>
                  </div>
                  <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'ml-3'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.003.853A.847.847 0 0011.546.1a.86.86 0 00-.946.099L6.016 4.006 1.419.19A.857.857 0 00.213.318a.849.849 0 00.128 1.24l5.136 4.25a.859.859 0 001.086 0l5.132-4.25a.85.85 0 00.308-.705z" fill="#2A2D52" fill-opacity=".6"/></svg>
                </div>
              </template>
              <b-dropdown-item id="show-active-202012301137"
                               @click="showPlaybooks('showActive')"
                               class="text-slate no-underline"
              >
                <i class="fas fa-file mr-1"></i>
                {{ staticText.showPlaybooksLabel }}
              </b-dropdown-item>

              <b-dropdown-divider></b-dropdown-divider>

              <b-dropdown-item id="show-templates-202012301137"
                               @click="showPlaybooks('showTemplates')"
                               class="text-slate no-underline"
              >
                <i class="fas fa-clone mr-1"></i>
                {{ staticText.showTemplateLabel }}
              </b-dropdown-item>

              <b-dropdown-divider></b-dropdown-divider>

              <b-dropdown-item id="show-inactive-202012301137"
                               @click="showPlaybooks('showInactive')"
                               class="text-slate no-underline"
              >
                <i class="fas fa-archive mr-1"></i>
                {{ staticText.showInactiveLabel }}
              </b-dropdown-item>
            </b-dropdown>
            <label-search
              :tags="playbookLabels"
              @filter="(selectedTags)=>filterPlaybooksByLabel(selectedTags)"
            ></label-search>
          </div>
        </template>

        <template slot="listActions">
          <div class="ml-auto d-flex">
            <b-btn id="new-playbook-btn-202012301137"
                   class="m-1 px-4 d-flex align-items-center"
                   variant="primary"
                   @click="openCreationModal"
            >
              <img src="/img/icons/light-plus-icon.svg"
                   class="mr-1"
              />
              {{ staticText.newPlaybookBtn }}
            </b-btn>
          </div>
        </template>
        <template v-slot:name="data">
          <div class="playbook">
            <router-link class="break-word" :to="'/templates/edit/' + data.cell.id">
              {{ data.cell.name }}
            </router-link>
            <base-tag-input
              :labels="data.cell.tags"
              :available-tags="playbookLabels"
              :item-identifier="data.index"
              @label-removed="(removedLabel, index) => labelRemoved(removedLabel, index, data)"
              @label-added="(newLabel) => labelAdded(newLabel, data)"
              :system-protected-tags="!data.cell.can_modify"
            ></base-tag-input>
          </div>
        </template>

        <template v-slot:last_used="data">
          <bao-date-time-display :value="data.cell.last_used"></bao-date-time-display>
        </template>

        <template v-slot:updated_at="data">
          <bao-date-time-display :value="data.cell.updated_at"></bao-date-time-display>
        </template>

        <template v-slot:created_by="data">
          {{ getCreatedBy(data.cell) }}
        </template>

        <template v-slot:actions="data">
          <div class="d-flex justify-content-between">
            <b-dropdown :id="'action-dropdown-202103211105-'+data.cell.id"
                        variant="btn-outline"
                        class="actions-dropdown"
                        no-caret
                        dropleft
            >
              <template v-slot:button-content>
                <img :id="'ellipsisIcon-' + data.cell.id"
                     src="/img/icons/points-icon.svg"
                />

                <b-tooltip :target="'ellipsisIcon-' + data.cell.id">
                  {{ staticText.moreLabel }}
                </b-tooltip>
              </template>
              <b-dropdown-item v-if="!showInactive"
                               :disabled="!data.cell.can_modify"
              >
                <b-btn :title='staticText.editBtn'
                       :disabled="!data.cell.can_modify"
                       :to="baseUrl + 'edit/' + data.cell.id"
                       variant="btn-outline"
                       class="w-100 text-left p-0"
                >
                  <img class="mr-1" src="/img/icons/edit-icon.svg"/>

                  <span>
                    {{ staticText.editBtn }}
                  </span>
                </b-btn>
              </b-dropdown-item>

              <b-dropdown-divider v-if="!showInactive"></b-dropdown-divider>

              <b-dropdown-item v-if="!showInactive">
                <!-- Duplicate -->
                <b-btn variant="btn-outline"
                       class="w-100 text-left p-0"
                       @click.stop="activateDuplicateModal(data.cell)"
                >
                  <img src="@/assets/svgs/duplicate-icon.svg" class="mr-1"/>

                  <span>
                    {{ staticText.duplicateBtn }}
                  </span>
                </b-btn>
              </b-dropdown-item>

              <b-dropdown-divider v-if="!showInactive"></b-dropdown-divider>

              <b-dropdown-item v-if="showInactive"
                               :disabled="!data.cell.can_modify"
              >
                <bao-delete-button
                  :label="staticText.deleteBtn"
                  :id="'playbook-delete-btn-'+data.cell.id"
                  extra-class="w-100 text-left p-0"
                  variant="btn-outline"
                  :disabled="!data.cell.can_modify"
                  @delete="deletePlaybook(data.cell.id, true)"
                >
                  <div class="delete-playbook-modal-title text-center mt-3">
                    {{ staticText.deletePlaybookWarningModalText1 }}
                  </div>

                  <div class="delete-playbook-modal-subtitle text-center mt-2">
                    {{ staticText.deletePlaybookWarningModalText2 }}
                  </div>
                </bao-delete-button>
              </b-dropdown-item>

              <b-dropdown-divider v-if="showInactive"></b-dropdown-divider>

              <b-dropdown-item :disabled="!data.cell.can_modify">
                <b-btn :title="getTitleForArchiveButton"
                       variant="btn-outline"
                       :disabled="!data.cell.can_modify"
                       class="w-100 text-left p-0"
                       @click.stop="deletePlaybook(data.cell.id)"
                >
                  <i class="fas fa-archive mr-1 text-slate-80"></i>

                  <span>
                    {{ getTitleForArchiveButton }}
                  </span>
                </b-btn>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </base-list>
    </div>

    <!-- Playbook creation -->
    <!-- Playbook Name Modal -->
    <b-modal id="new-playbook-202012301137"
             v-model="showPlaybookCreationModal"
             centered
             hide-footer
    >
      <template v-slot:modal-header-close>
        <img src="/img/icons/close-icon.svg"/>
      </template>
      <template v-slot:modal-title>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'mr-2'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4 1h5.625a.375.375 0 01.369.308l.006.067V7c0 .79.612 1.438 1.387 1.496l.113.004h5.625a.375.375 0 01.369.307l.006.068V17.5a1.5 1.5 0 01-1.387 1.496L16 19H4a1.5 1.5 0 01-1.496-1.387L2.5 17.5v-15c0-.79.612-1.438 1.388-1.496L4 1h5.625H4zm7.125 6V1.646a.113.113 0 01.164-.1l.028.021 5.616 5.616a.112.112 0 01.03.106.113.113 0 01-.075.08l-.035.006H11.5a.375.375 0 01-.369-.308L11.125 7V1.646 7z" fill="#2B2727" opacity=".8"/></svg>
        <span>
          {{ staticText.playbookCreationTitle }}
        </span>
      </template>
      <div class="new-playbook-form">
        <div class="input-wrapper mb-2">
          <b-form-input id="playbook-name-202012301137"
                        v-model="playbookName"
                        :placeholder="staticText.newPlaybookNamePlaceholder"
                        :state="validatePlaybookName()"
                        type="text"
          ></b-form-input>
          <span id="playbook-name-label-202012301137"
                class="input-label"
          >
            {{ staticText.newPlaybookName }}
          </span>
          <b-form-invalid-feedback id="name-input-live-feedback">{{ playbookNameFeedback }}</b-form-invalid-feedback>
        </div>
        <multiselect id="template-selection-202012301137"
                     v-model="templateSelected"
                     :options="templateList"
                     :allow-empty="false"
                     :custom-label="templateLabel"
                     :placeholder="staticText.playbookTemplate"
                     :show-labels="false"
                     track-by="id"
                     class="mt-1 mb-3"
        ></multiselect>
        <div id="saving-as-spinner-202012301137"
             v-if="savingAs"
             class="playbook-saving-spinner mr-2 mb-3"
        >
          <b-spinner variant="success"
                     small
          ></b-spinner>
          {{ staticText.creatingPlaybook }}
        </div>

        <div class="mt-4 text-center d-flex justify-content-between">
          <b-btn id="cancel-btn-202012301137"
                 variant="secondary"
                 class="font-size-14 px-4"
                 @click="cancelPlaybookCreation"
          >
            {{ staticText.cancel }}
          </b-btn>

          <b-btn id="save-and-proceed-btn-202012301137"
                 :disabled="disableSaveButton"
                 variant="primary"
                 class="font-size-14 px-4"
                 @click="cloneTemplateFromSelected"
          >
            {{ staticText.saveAndProceed }}
          </b-btn>
        </div>
      </div>
    </b-modal>

    <!-- Duplicate Modal -->
    <PlaybookDuplicateModal
      v-model="showDuplicateModal"
      :playbook="playbookToClone"
      @close="showDuplicateModal=false"
    />

  </div>
</template>

<script>
import BaseList from "../base/BaseList"
import appInfo from "."
import axios from "axios"
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"
import { playbookServices } from "./PlaybookServices"
import BaoDateTimeDisplay from "@/apps/base/BaoDateTimeDisplay"
import { mapActions, mapGetters } from "vuex"
import BaseTagInput from "@/apps/base/BaseTagInput"
import LabelSearch from "@/apps/talkscript/components/LabelSearch"
import PlaybookDuplicateModal from "@/apps/talkscript/components/PlaybookDuplicateModal"

const Multiselect = () => import("vue-multiselect")

export default {
  name: "PlaybookList",
  components: {
    LabelSearch,
    BaseList,
    Multiselect,
    BaoDeleteButton,
    BaoDateTimeDisplay,
    BaseTagInput,
    PlaybookDuplicateModal
  },
  data () {
    return {
      axios,
      playbookList: [],
      selectedLabels: [],
      selectedCounterpart: null,
      baseUrl: appInfo.route.path + "/",
      detail_item_id: "",
      sortBy: "last_used",
      sortDesc: true,
      isLoading: false,
      templates: [
        { name: "name", field: "name" },
        { name: "last_used", field: "last_used" },
        { name: "created_by", field: "created_by" },
        { name: "actions", field: "actions" },
        { name: "updated_at", field: "updated_at" }
      ],
      useCreateForm: false,
      showItemForm: false,
      showCounterpartPersonality: false,
      totalItems: null,
      loading: false,
      deleteWarningModal: false,
      staticTextDefault: {
        duplicateBtn: "Duplicate",
        newPlaybookBtn: "New Playbook",
        editBtn: "Edit",
        deleteBtn: "Delete",
        archiveBtn: "Archive",
        unArchiveBtn: "Unarchive",
        deletePlaybookWarningModalText1: "Deleting a playbook is an irreversible action.",
        deletePlaybookWarningModalText2: "Are you sure you want to delete the playbook?",
        newPlaybookName: "New Playbook Name",
        newPlaybookNamePlaceholder: "Enter new playbook name",
        playbookTemplate: "Playbook Template",
        playbookTemplatePlaceholder: "Select template for playbook",
        creatingPlaybook: "Creating Playbook...",
        cancel: "Cancel",
        saveAndProceed: "Save and Proceed",
        blankTemplateTitle: "Blank Template",
        playbookCreationTitle: "New Playbook Creation",
        NameLabel: "Name",
        lastUsedLabel: "Last Used",
        modifiedByLabel: "Modified",
        createdByLabel: "Created By",
        actionsLabel: "Actions",
        playbooksDisplayName: "Playbooks",
        showInactiveBtnLabel: "Show Archived Playbooks",
        showActiveBtnLabel: "Show Active Playbooks",
        showPlaybooksLabel: "Playbooks",
        showInactiveLabel: "Archived Playbooks",
        showTemplateLabel: "Templates",
        showShortcutsLabel: "Shortcuts",
        moreLabel: "More",
        playbookNameMaxCharacterLimitMessage: "The playbook name cannot exceed 120 characters."
      },
      showPlaybookCreationModal: false,
      playbookName: "",
      playbookNameValid: false,
      playbookNameFeedback: "",
      templateSelected: null,
      templateList: [],
      savingAs: false,
      showActive: true,
      showInactive: false,
      showTemplates: false,
      showObjections: false,
      playbookLabels: [],
      showDuplicateModal: false,
      playbookToClone: null
    }
  },
  computed: {
    ...mapGetters({
      nonSystemLabels: "labels/getAllNonSystemLabels"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    playbookNameState () {
      return this.playbookName !== "" && this.playbookNameFeedback === ""
    },
    getTitleForArchiveButton () {
      return !this.showInactive ? this.staticText.archiveBtn : this.staticText.unArchiveBtn
    },
    getVariantForArchiveButton () {
      return !this.showInactive ? "danger" : "secondary"
    },
    disableSaveButton () {
      return !this.playbookNameState || !this.templateSelected || this.savingAs
    }
  },
  async mounted () {
    await this.fetchAllNonSystemLabels()
    this.playbookLabels = this.nonSystemLabels.filter(label => label.name.includes("playbook/"))
  },
  watch: {
    "$route" (to) {
      this.detail_item_id = to.params.id
    },
    nonSystemLabels () {
      this.playbookLabels = this.nonSystemLabels.filter(label => label.name.includes("playbook/"))
    }
  },
  created () {
    this.getTemplates().then(this.getPlaybooks)
  },
  methods: {
    ...playbookServices.methods,
    ...mapActions({
      fetchAllNonSystemLabels: "labels/fetchAllNonSystemLabels"
    }),
    activateDuplicateModal (playbook) {
      this.showDuplicateModal = true
      this.playbookToClone = playbook
    },
    getVariantForButton (value) {
      return value ? "active-tab" : "inactive-tab"
    },
    showPlaybooks (field) {
      if (this[field]) return
      this.showActive = false
      this.showInactive = false
      this.showTemplates = false
      this.showObjections = false
      this.$set(this, field, true)
      this.getPlaybooks()
    },
    getBlankTemplate () {
      return { name: this.staticText.blankTemplateTitle }
    },
    filterList (value) {
      return this.$router.push({ query: { ...this.$route.query, name: value, currentPage: 1 } }).then(this.getPlaybooks)
    },
    deletePlaybook (playbookId, force = false) {
      const params = {}
      if (force) params.force = force
      return this.axios.delete(appInfo.apiUrl.playbook + "/" + playbookId, { params }).then(() => {
        this.getPlaybooks()
      }, error => {
        this.error = error.response
      })
    },
    cancelPlaybookCreation () {
      this.showPlaybookCreationModal = false
      this.playbookName = ""
    },
    cloneTemplateFromSelected () {
      const that = this
      that.savingAs = true
      return new Promise((resolve, reject) => {
        that.cloneTemplate(that.templateSelected.id, that.playbookName)
          .then(newPlaybookId => {
            that.showPlaybookEdit(newPlaybookId)
            that.savingAs = false
            that.showPlaybookCreationModal = false
            resolve()
          }).catch(error => {
            that.savingAs = false
            reject(error)
          })
      })
    },
    showPlaybookEdit (playbookId) {
      this.$router.push(appInfo.route.path + "/edit/" + playbookId)
    },
    openCreationModal () {
      return this.getTemplates().then(() => {
        this.showPlaybookCreationModal = true
      })
    },
    templateLabel (item) {
      return item.name + (item.created_by ? " - " + item.created_by.first_name + " " + item.created_by.last_name : "")
    },
    handlePageChanged (pageNumber) {
      this.$router.push({ query: { ...this.$route.query, currentPage: pageNumber } })
      this.getPlaybooks()
    },
    handleSortingChanged (newSort, sortDirection) {
      if (this.sortBy === newSort && this.sortDesc === sortDirection) {
        return
      }
      this.sortBy = newSort
      this.sortDesc = sortDirection
      this.getPlaybooks()
    },
    getPlaybooks () {
      const that = this
      const params = {}
      that.playbookList = [] // TODO: check old code: JSON.parse(JSON.stringify(that.templateList)).filter(item => !!item.main_container)
      const getUrl = appInfo.apiUrl.playbook
      params.page = this.$route.query.currentPage ? this.$route.query.currentPage : 1
      params.page_size = this.$route.query.page_size ? this.$route.query.page_size : 10
      params.ordering = (this.sortDesc ? "-" : "") + this.sortBy
      params.active = !this.showInactive
      if (this.selectedLabels.length > 0) {
        params.tags__name__in = this.selectedLabels.toString()
      }
      if (this.showTemplates) params.is_template = true
      else if (this.showObjections) params.type = "objection"
      else params.type = "default"

      if (this.$route.query.name && this.$route.query.name.length > 0) params.name__icontains = this.$route.query.name

      this.loading = true
      return that.axios.get(getUrl, { params }).then(response => {
        that.playbookList = response.data.results
        that.totalItems = response.data.count
        this.loading = false
      })
    },
    getTemplates () {
      const params = { is_template: true, active: true }
      return this.axios.get(appInfo.apiUrl.playbook, { params })
        .then(response => {
          const blankTemplate = this.getBlankTemplate()
          this.templateList = [blankTemplate].concat(response.data.results)
          this.templateSelected = blankTemplate
        })
    },
    getCreatedBy (item) {
      return item.created_by ? item.created_by.first_name + " " + item.created_by.last_name : "System"
    },
    getFields () {
      return [
        { key: "name", label: this.staticText.NameLabel, sortable: true, sortDirection: "desc" },
        { key: "last_used", label: this.staticText.lastUsedLabel, sortable: true },
        { key: "updated_at", label: this.staticText.modifiedByLabel, sortable: true },
        { key: "created_by", label: this.staticText.createdByLabel, sortable: true },
        { key: "actions", label: this.staticText.actionsLabel }
      ]
    },
    labelRemoved (removedLabel, index, data) {
      data.cell.tags.splice(index, 1)
      this.editPlaybookLabels(data.cell.id, data.cell.tags, removedLabel)
    },
    labelAdded (newLabel, data) {
      data.cell.tags.push(`playbook/${newLabel}`)
      this.editPlaybookLabels(data.cell.id, data.cell.tags)
    },
    async editPlaybookLabels (id, newLabels, removedLabel = null) {
      try {
        await axios.post(`api/tags/add_tags?model=talkscripts_Talkscript&id=${id}`, {
          tags: newLabels
        })
        await this.fetchAllNonSystemLabels()
        if (removedLabel) {
          // This is to check if the removed label is part of the filtered labels so we can
          // re-filter if a filtered label was removed
          const removedIndex = this.selectedLabels.findIndex(label => label === removedLabel)
          if (removedIndex !== -1) {
            if (!this.playbookLabels.find(tag => tag.name === removedLabel)) {
              this.selectedLabels.splice(removedIndex, 1)
            }
            this.getPlaybooks()
          }
        }
      } catch (error) {
      }
    },
    filterPlaybooksByLabel (selectedLabels) {
      this.selectedLabels = selectedLabels
      this.getPlaybooks()
    },
    validatePlaybookName () {
      if (this.playbookName.length > 120) {
        this.playbookNameFeedback = this.staticText.playbookNameMaxCharacterLimitMessage
      } else this.playbookNameFeedback = ""
      return this.playbookNameFeedback === ""
    }
  }
}
</script>

<style scoped lang="scss">

.delete-playbook-modal-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: $slate;
}

.delete-playbook-modal-subtitle {
  font-size: 16px;
  line-height: 19px;
  color: $slate80;
}
</style>

<style lang="scss">

.custom-tooltip > .tooltip-inner {
  max-width: unset !important;
  padding: 8px 2px 8px 8px !important;
}
</style>
